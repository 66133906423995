<template>
    <!-- 경영공시 START -->
	<div class="content_container ceo">
		<div class="content">
			<div class="banner">
				<h1>회사소개</h1>
			</div>
			<h2 class="title_product">CEO 인사말</h2>
			<section>
				<div>
					<p><img src="@/assets/images/sub/ceo/ceo.jpg" alt="ceo 손이미지" /></p>
				</div>
				<div class="ceo_contents">
					<div class="ceo_sub_title">
						<p>든든한 금융 파트너<br /><span>무궁화캐피탈</span></p>
					</div>
					<div class="ceo_text">
						<p>
							<i>안녕하십니까</i><br />무궁화캐피탈 대표이사 이정호 입니다.
						</p>
						<p>
							무궁화캐피탈은 대주주 변경으로 무궁화신탁 금융그룹의 관계사로 편입 되어 상호를 무궁화캐피탈로 변경하고 새로운 성장과 도약을 준비하는 계기를 마련하였습니다.<br /><br />
							고객님의 행복한 미래를 최고의 가치로 고객님에게 힘이 되는 기업자금대출, 부동산 프로젝트파이낸싱, 투자금융, 리스금융, 할부금융 등 전문화된 금융서비스를 지원하는 여신전문 금융회사로서 여러분의 비즈니스에 도움을 드릴 수 있도록 최선을 다하겠습니다.<br /><br />
							앞으로도 무궁화캐피탈은 고객님의 든든한 파트너로서 미래 성장을 도모하고, 고객과 함께 웃으며 동행하는 우량 여신전문 금융회사가 되고자 하오니 따뜻한 눈길로 지켜봐 주시기 바랍니다.<br /><br />
							감사합니다.<br />
						</p>
							

						<div class="ceo_signature">
							<p>무궁화캐피탈 대표이사</p>
							<p>이정호</p>
						</div>	
					</div>
				</div>
			</section>	
		</div>
	</div>
    <!-- 경영공시 END -->
</template>

<script>
export default {
    name : "Ceo",
}
</script>

